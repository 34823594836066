<template>
  <el-card class="controll-pannel-card">
    <FileUpload />
  </el-card>
</template>
<style scoped>
.el-card {
  margin-bottom: 20px;
}
</style>
<script>
import Vue from "vue";
import { Card } from "element-ui";
import FileUpload from "@/components/manage/FileUpload.vue";
const components = [Card];
components.forEach((component) => {
  Vue.use(component);
});
export default {
  name: "Upload",
  components: {
    FileUpload,
  },
};
</script>