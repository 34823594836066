<template>
  <el-upload
    class="upload"
    drag
    action="https://www.truthahn.top:9001/api/uploadfile"
    multiple
    :on-success="handleSucess"
    :on-error="handleError"
    :before-upload="beforeUpload"
  >
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
  </el-upload>
</template>
<script>
import Vue from "vue";
import { Upload, Message } from "element-ui";
const components = [Upload];
components.forEach((component) => {
  Vue.use(component);
});
export default {
  name: "FileUpload",
  methods: {
    handleSucess(response, file) {
      Message.success(`${file["name"]} 上传成功!`);
    },
    handleError(response, file) {
      Message.error(`${file["name"]} 上传出错!`);
    },
    beforeUpload(file) {
      const isTXT = file.type === "text/plain";
      if (!isTXT) {
        Message.error("上传文件只能是 txt 格式!");
      }
      return isTXT;
    },
  },
};
</script>